import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import Button from "react-bootstrap/Button";
import axios from "axios";
import * as XLSX from "xlsx";

function UserManagement() {
  const [userdata, setuserdata] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    getcustomers();
  }, []);

  const getcustomers = async () => {
    let res = await axios.get("https://api.thevucare.com/api/getcustomer");
    if ((res.status = 200)) {
      setuserdata(res.data?.customers);
      setSearchResults(res.data?.customers);
    }
  };

  const deleteuser = async (id) => {
    axios({
      method: "post",
      url: "https://api.thevucare.com/api/deletecustomer/" + id,
    })
      .then(function (response) {
        //handle success
        console.log(response);
        alert("Deleted successfully");
        window.location.reload();
      })
      .catch(function (error) {
        //handle error
        console.log(error.response.data);
      });
  };

  // Pagination logic
  const totalPages = Math.ceil(searchResults.length / itemsPerPage);
  const pageOptions = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  // Get current items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  // Function to handle Excel download
  const handledownload = () => {
    const ws = XLSX.utils.json_to_sheet(userdata.map((user, index) => ({
      "S.No": index + 1,
      "User Name": user.customerName,
      "User Email": user.email,
      "User Contact No.": user.mainContact,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customers");
    XLSX.writeFile(wb, "CustomerData.xlsx");
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <Sidenav />
      </div>
      <div className="col-md-10 ">
        <Header />

        <div className="m-auto" style={{ marginLeft: "-32px" }}>
          <div>
            <h3> Customers</h3>
            <div className="row m-auto">
                <div className="col-md-2 me-auto pagination mt-4">
                  <span>Page </span>
                  <select
                    className="m-1"
                    value={currentPage}
                    onChange={(e) => handlePageChange(Number(e.target.value))}
                  >
                    {pageOptions.map((page) => (
                      <option value={page} key={page}>
                        {page}
                      </option>
                    ))}
                  </select>
                  <span> of {totalPages}</span>
                </div>
                <div className="col-md-2 ">
                <button className="row btndow p-2 " onClick={handledownload}>Download</button>
              </div>

              </div>

            <table className="table table-hover table-bordered mt-2">
              <thead className="text-align-center">
                <tr className="table-secondary">
                  <th className="table-head" scope="col">S.No</th>
                  <th className="table-head" scope="col">User Name</th>
                  <th className="table-head" scope="col">User Email</th>
                  <th className="table-head" scope="col">User Contact No.</th>
                  <th className="table-head" scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="justify-content-center">
                {currentItems.map((item, index) => (
                  <tr className="user-tbale-body text-center" key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.customerName}</td>
                    <td>{item.email}</td>
                    <td>{item.mainContact}</td>
                    <td>
                      <Button
                        className="m-1 row"
                        onClick={() => deleteuser(item._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
