import React from "react";

function Product() {
  return (
    <div>
      <p>Product page</p>
    </div>
  );
}

export default Product;
